import {
  DEFAULT_EXPERIMENT,
  OFF_EXPERIMENT,
  UNASSIGNED_EXPERIMENT,
  getPageVariationByParam,
} from 'constants/experiments'
import { ANALYTICS } from 'constants/analytics'
import Cookies from 'js-cookie'

//Gets experiment
const getExperiment = ({ featureToggleObject, queryParameters, isCrawler, data }) => {
  const pageLevelFeatureFlagId = data?.items[0]?.fields?.experiment?.fields?.featureFlagId
  // Set the experiment to "Control" by default. Bots should always be served the default.
  let experiment = DEFAULT_EXPERIMENT

  if (pageLevelFeatureFlagId && !isCrawler) {
    const experimentBucket = queryParameters?.v
      ? getPageVariationByParam(queryParameters?.v)
      : featureToggleObject[pageLevelFeatureFlagId]

    // Change the experiment to bucket assigned from LD if it's not blacklisted
    if (![OFF_EXPERIMENT, UNASSIGNED_EXPERIMENT, false].includes(experimentBucket)) {
      experiment = experimentBucket
    }
  }
  return experiment
}

const checkIfExperimentIsOn = (experiment) =>
  experiment !== 'Control' &&
  experiment !== 'Unassigned' &&
  experiment !== 'Off' &&
  experiment !== undefined &&
  experiment !== false

const shouldTrackSessionExperiment = (experiment) => {
  if (!experiment) {
    return false
  }

  const sessionStartEventsTracked = Cookies.get(ANALYTICS.SESSION_START_EVENTS_TRACKED)
  return (
    experiment &&
    !sessionStartEventsTracked &&
    ![OFF_EXPERIMENT, UNASSIGNED_EXPERIMENT].includes(experiment)
  )
}

const shouldTrackPageLevelExperiment = (experiment) => {
  if (!experiment) {
    return false
  }
  const lastPageLevelExperiment = Cookies.get(ANALYTICS.LAST_PAGE_LEVEL_EXPERIMENT)
  return experiment && lastPageLevelExperiment !== experiment
}

const shouldWebDrMpVsLxExperiment = (experiment, pathname) => {
  if (!experiment) {
    return false
  }
  return !['/a/[slug]', '/l/[slug]'].includes(pathname)
}

const createExperimentObject = (name, id, variation, type) => ({
  experiment_name: name,
  experiment_id: id,
  variation: variation,
  event_type: type,
})

export {
  getExperiment,
  checkIfExperimentIsOn,
  shouldTrackSessionExperiment,
  shouldTrackPageLevelExperiment,
  createExperimentObject,
  shouldWebDrMpVsLxExperiment,
}
