import { Fragment, useContext } from 'react'
import PropTypes from 'prop-types'
import { AchieveLink } from 'components/AchieveLink/AchieveLink'
import { Typography } from '@achieve/ascend'
import useMobileNavState from 'hooks/useMobileNavState'
import useActiveNavItemIndex from 'hooks/useActiveNavItemIndex'
import { useViewportSmallerThan } from 'utils/viewport'
import { BREAKPOINTS } from 'constants/viewport'
import useIsSsr from 'hooks/useIsSsr'
import styles from './Navigation.module.scss'
import FEATURE_TOGGLES from 'constants/featureToggles'
import { mainProductConfig } from 'services/contentful/helpers'
import { LayoutContext } from 'providers/LayoutProvider'
/**
 * @param {{
 *  currentPathname: string
 *  disabledRoutes: []
 *  items: []
 *  mainNavLabel: string
 *  title: string
 * }} props
 */
export default function SubNavMenu({
  currentPathname,
  disabledRoutes,
  disabledElements,
  items = [],
  mainNavLabel,
  title,
}) {
  const [, setMobileNavOpen] = useMobileNavState()
  const [, setActiveIndex] = useActiveNavItemIndex()
  const isSsr = useIsSsr()
  const { state: { siteEnv } = {} } = useContext(LayoutContext)
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)

  // When an item with a route is clicked, the mobile nav should be dismissed,
  // active item should be cleared, and the page should scroll to the top if the
  // render is happening in the browser
  const handleItemClick = () => {
    isMobile && !isSsr && window.scrollTo({ top: 0, behavior: 'instant' })
    setMobileNavOpen()
    setActiveIndex()
  }

  return (
    <div
      className={
        styles[
          `sub-nav-menu${
            disabledElements[FEATURE_TOGGLES.ACX_WEB_ENABLE_NAV_TITLES] ? '' : '-without-subtitle'
          }`
        ]
      }
      data-nav="container"
    >
      {disabledElements[FEATURE_TOGGLES.ACX_WEB_ENABLE_NAV_TITLES] && (
        <div className={styles['menu-title-container']}>
          <Typography
            variant="bodySm"
            fontWeight="bold"
            component="div"
            className={styles['menu-title']}
          >
            {title}
          </Typography>
        </div>
      )}
      <nav role="navigation">
        <ul>
          {items.map(({ label, description, testid, url = '#' }, index) => (
            <Fragment key={label}>
              {!disabledRoutes.includes(url) && (
                <li>
                  <AchieveLink
                    href={url}
                    withNextLink
                    track={{
                      list_name: 'Header Nav Link Click',
                      mega_menu_tab: mainNavLabel,
                      mega_menu_column: title,
                      mega_menu_link: label,
                      click_id: label,
                      click_position: index + 1,
                      click_text: `${mainProductConfig[siteEnv || 'achieve']?.['MainProductHyphen']} | ${label}`,
                      nav_link_section: 'Mega Menu',
                      track_event: 'mega_menu',
                      event_action: 'link_click',
                      event_type: 'link_click',
                    }}
                  >
                    {/* Have to use a regular a tag since AchieveLink can't forwardRef to a
                    functional component so AscendLink doesn't work. */}
                    <span
                      className={styles['sub-nav-link']}
                      data-selected={currentPathname === url}
                      onClick={() => {
                        handleItemClick()
                      }}
                      data-testid={testid ? testid : `header-menu-${index}`}
                      data-nav="sub-nav"
                    >
                      <Typography
                        variant={isMobile ? 'bodySm' : 'bodyLg'}
                        fontWeight={isMobile ? 'normal' : 'bold'}
                        component="div"
                      >
                        {label}
                      </Typography>
                      {!isMobile && (
                        <Typography
                          className={styles['sub-nav-link-description']}
                          variant="bodySm"
                          fontWeight="normal"
                          component="div"
                        >
                          {description}
                        </Typography>
                      )}
                    </span>
                  </AchieveLink>
                </li>
              )}
            </Fragment>
          ))}
        </ul>
      </nav>
    </div>
  )
}

SubNavMenu.propTypes = {
  currentPathname: PropTypes.string.isRequired,
  disabledRoutes: PropTypes.array,
  mainNavLabel: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  items: PropTypes.array,
}
