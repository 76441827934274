import { MediaImageStandard, Typography, LinkButton } from 'components/Contentful'
import { Grid } from '@achieve/ascend'
import { useViewportSmallerThan } from 'utils/viewport'
import { BREAKPOINTS } from 'constants/viewport'
import styles from './CardCTA.module.scss'
import { useCurrentUrl } from 'hooks/useCurrentUrl'
import { isExternal } from 'utils/conversions'
import { mainProductConfig } from 'services/contentful/helpers'
import { useContext } from 'react'
import { LayoutContext } from 'providers/LayoutProvider'

export const CardCTA = ({ content, variant, trackingName }) => {
  let { link, title, subtitle, image } = content
  const isMobile = useViewportSmallerThan(BREAKPOINTS.md)
  const currentURL = useCurrentUrl()
  const { state: { siteEnv } = {} } = useContext(LayoutContext)

  return (
    <div className={styles[`outer-wrapper-${variant ? variant : 'main'}`]}>
      <Grid className={styles['container']}>
        <Grid className={styles['inner-container']}>
          {image && (
            <div className={styles['image-container']}>
              <MediaImageStandard
                className={styles['image']}
                alt={image.fields.mediaAltText}
                content={image.fields.mediaContent}
                data-testid="cta-card-main-image"
                objectFit="cover"
              />
            </div>
          )}
          <Grid className={styles['text-container']}>
            <Typography
              className={styles['cta-title']}
              fontWeight={variant ? 'medium' : 'bold'}
              variant={variant ? 'headingMd' : isMobile ? 'headingMd' : 'displayMd'}
              content={title}
            ></Typography>
            {subtitle && (
              <Typography
                className={styles['cta-subtitle']}
                variant={variant ? 'bodySm' : 'displayMd'}
                content={subtitle}
              ></Typography>
            )}
            <LinkButton
              track={{
                list_name: 'CTA CARD',
                click_position: '0',
                track_event: 'page_nav_click',
                event_action: 'button_click',
                event_type: 'button_click',
                click_id: link?.linkText?.json?.content[0]?.content[0]?.value,
                click_text: `${mainProductConfig[siteEnv || 'achieve']?.['MainProductHyphen']} | ${link?.linkText?.json?.content[0]?.content[0]?.value}`,
                nav_link_section: `CTA Card - ${trackingName}`,
                click_type:
                  isExternal(link?.fields?.linkHref, currentURL) ||
                  link?.fields?.linkHref?.includes('get-started')
                    ? 'internal_campaign_click'
                    : 'page_nav_click',
              }}
              className={styles['cta-button']}
              content={link}
              data-testid="cta-card-button"
              {...(variant && {
                variant: variant ? (variant == 'widget' ? 'outlined' : 'primary') : null,
                color: variant ? (variant == 'widget' ? 'primary' : 'secondary') : null,
              })}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
