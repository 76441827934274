import googlebotIPs from 'constants/googlebotIPs.json'
import { default as isIpFromList } from 'ip-range-check'

export const getIpFromReq = (req) => {
  return (
    req.headers['incap-client-ip'] ||
    req.headers['x-real-ip'] ||
    req.headers['x-forwarded-for'] ||
    req.socket?.ip ||
    req.socket?.remoteAddress
  )
}

export function detectCrawler(req) {
  if (!req) return false
  const ip = getIpFromReq(req)
  return isIpFromList(ip, googlebotIPs)
}
