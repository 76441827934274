/**
 * Takes contentful publishDate and formats it correctly
 * @param {String} publishDate
 * @param {Boolean} shortMonth
 * @returns {String} formatted date string
 */
function formatPublishDate(publishDate, shortMonth = false) {
  if (!publishDate) return ''
  const [year, month, day] = publishDate.split('T')[0].split('-')
  const dateObject = new Date(year, month - 1, day)
  const dateString = `${dateObject.toLocaleString('en-US', {
    month: shortMonth ? 'short' : 'long',
  })} ${day}, ${year}`
  return dateString
}

export { formatPublishDate }
