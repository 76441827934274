import { InMemoryCache, ApolloClient } from '@apollo/client'
import { ApolloLink } from 'apollo-link'
import { HttpLink } from 'apollo-link-http'
import 'whatwg-fetch'

const TOKEN = process.env.CONTENTFUL_ACCESS_TOKEN
const SPACE = process.env.CONTENTFUL_SPACE_ID
const environment = process.env.CONTENTFUL_ENVIRONMENT
const URL = `https://graphql.contentful.com/content/v1/spaces/${SPACE}/environments/${environment}`

const http = new HttpLink({
  uri: URL,
  headers: {
    Authorization: `Bearer ${TOKEN}`,
  },
  next: { revalidate: 0 },
})

const link = ApolloLink.from([http])

let CLIENT
let apolloClient

const isServer = typeof window === 'undefined'
const windowApolloState = !isServer && window?.__NEXT_DATA__?.apolloState

/*
  Apollo Client Singleton - Unadviced for SSR
  Allows for cache clear throug /api/
  ISR recomended
*/
if (!global.apolloClient) {
  global.apolloClient = (forceNew) => {
    if (!CLIENT || forceNew) {
      CLIENT = new ApolloClient({
        link,
        ssrMode: isServer,
        cache: new InMemoryCache().restore(windowApolloState || {}),
        connectToDevTools: true,
      })

      //Client instance identifier.
      CLIENT.uid = Math.random()
      CLIENT.createdAt = new Date()
      CLIENT.updatedAt = new Date()
    }

    return CLIENT
  }
}

//Exports reference to global.
apolloClient = global.apolloClient

const resetCache = async () => {
  await apolloClient().resetStore()
  await apolloClient().clearStore()
  await apolloClient().cache.gc()
  apolloClient().updatedAt = new Date()
}

const getCacheUID = () => {
  return apolloClient().uid
}

export default apolloClient
export { resetCache, getCacheUID }
