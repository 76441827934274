import { BLOCKS } from '@contentful/rich-text-types'
import { Typography } from 'components/Contentful'
import styles from './StickySubNavSection.module.scss'
import { useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { normalizeContent } from 'utils/shared/hacks'
import { replaceGlobalVars } from 'utils/shared'
import { GET_CONTENT_BY_ID, GET_GLOBAL_VARIABLES } from 'utils/contentful/GraphqlQueries'
import dynamic from 'next/dynamic'
const AchieveSkeleton = dynamic(() => import('components/AchieveSkeleton/AchieveSkeleton'))

const StickySubNavSection = ({ sectionContent, index, addToRefs, makeTestId, pageTestIdScope }) => {
  return (
    <div
      ref={(el) => addToRefs(el, index)}
      className={styles['body-text-section']}
      id={sectionContent.fields.name.replaceAll(' ', '-')}
      data-section-index={index}
      key={sectionContent?.sys?.id}
      data-testid={makeTestId(
        `subsection-container-${sectionContent.fields.name.replaceAll(' ', '-')}`,
        pageTestIdScope
      )}
    >
      {sectionContent?.fields?.textContent && (
        <Typography
          className={styles['table-custom']}
          breakpoint="lg"
          responsive={true}
          variantOverride={{
            [BLOCKS.HEADING_2]: 'headingLg',
            [BLOCKS.HEADING_3]: 'displayMd',
            [BLOCKS.HEADING_4]: 'bodyLg',
            [BLOCKS.PARAGRAPH]: 'bodySm',
          }}
          mobileVariantOverride={{
            [BLOCKS.HEADING_2]: 'displayMd',
            [BLOCKS.HEADING_3]: 'bodyLg',
            [BLOCKS.HEADING_4]: 'bodySm',
            [BLOCKS.PARAGRAPH]: 'bodySm',
          }}
          content={sectionContent?.fields?.textContent}
          styleOverride={{
            [BLOCKS.HEADING_2]: { marginBottom: '16px' },
            [BLOCKS.HEADING_3]: { marginBottom: '36px', marginTop: '36px' },
            [BLOCKS.HEADING_4]: { marginBottom: '36px', marginTop: '36px' },
            [BLOCKS.PARAGRAPH]: { marginBottom: '16px' },
          }}
        />
      )}
    </div>
  )
}

const StickySubNavSectionScroll = ({
  sectionContent,
  setSectionContents,
  scrollRender = false,
  ...props
}) => {
  const [fetchTextContent] = useLazyQuery(GET_CONTENT_BY_ID, {
    variables: {
      id: sectionContent.fields.sys.id,
    },
  })
  const [fetchGlobalVariables] = useLazyQuery(GET_GLOBAL_VARIABLES)
  const [sectionContentData, setSectionContentData] = useState(normalizeContent(sectionContent))

  useEffect(() => {
    processFetchTextContent()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const processFetchTextContent = async () => {
    const sectionData = sectionContent
    const { data } = await fetchTextContent()
    sectionData.fields.textContent = data?.contentTypeRichText?.textContent
    const getGlobalVariables = await fetchGlobalVariables()
    const globalVariables =
      getGlobalVariables?.data?.jsonFieldCollection?.items?.[0]?.jsonContent || {}
    setSectionContentData(replaceGlobalVars(normalizeContent(sectionData), globalVariables))
    setSectionContents(replaceGlobalVars(normalizeContent(sectionData), globalVariables))
  }

  if (scrollRender && !sectionContentData.fields.textContent) {
    return <AchieveSkeleton height={300} />
  }

  return (
    <StickySubNavSection
      sectionContent={sectionContentData}
      scrollRender={scrollRender}
      {...props}
    />
  )
}

export { StickySubNavSection, StickySubNavSectionScroll }
export default StickySubNavSection
