import { Grid } from '@achieve/ascend'
import styles from './CardBottomCTA.module.scss'
import { LinkButton, MediaImageStandard, Typography } from 'components/Contentful'
import { isExternal } from 'utils/conversions'
import { useCurrentUrl } from 'hooks/useCurrentUrl'
import { mainProductConfig } from 'services/contentful/helpers'
import { useContext } from 'react'
import { LayoutContext } from 'providers/LayoutProvider'

export const CardBottomCTA = ({ content }) => {
  let { cardTitleText, cardText, cardBadgeImage, cardLink } = content
  const currentURL = useCurrentUrl()
  const { state: { siteEnv } = {} } = useContext(LayoutContext)


  return (
    <div className={styles['container']}>
      <Grid container xs={12} className={styles['card']}>
        <Grid
          item
          xs={12}
          display={'flex'}
          justifyContent={'center'}
          padding={2}
          className={styles['contianer-img']}
        >
          <MediaImageStandard
            className={styles['badge-img']}
            alt={cardBadgeImage?.fields?.mediaAltText}
            content={cardBadgeImage?.fields?.file}
            layout="fixed"
            width={55}
            height={55}
            priority={true}
          />
        </Grid>
        {cardTitleText && (
          <Grid item xs={12} marginTop={-4}>
            <Typography content={cardTitleText} variant="headingSm" textAlign="center" />
          </Grid>
        )}
        {cardText && (
          <Grid item xs={12}>
            <Typography content={cardText} variant="bodySm" textAlign="center" />
          </Grid>
        )}
        {cardLink && (
          <Grid
            item
            xs={12}
            display={'flex'}
            justifyContent={'center'}
            className={styles['button-link']}
          >
            <LinkButton
              track={{
                list_name: 'Cta Button',
                click_id: cardLink?.fields?.linkText?.content[0]?.content[0]?.value,
                click_text: `${mainProductConfig[siteEnv || 'achieve']?.['MainProductHyphen']} | ${cardLink?.fields?.linkText?.content[0]?.content[0]?.value}`,
                nav_link_section: `Cta Button`,
                click_type:
                  isExternal(cardLink?.fields?.linkHref, currentURL) ||
                  cardLink?.fields?.linkHref?.includes('get-started')
                    ? 'internal_campaign_click'
                    : 'page_nav_click',
              }}
              content={cardLink}
              data-testid="card-cta-button"
              typographicOptions={{ variant: 'labelMd' }}
              fullWidth
            />
          </Grid>
        )}
      </Grid>
    </div>
  )
}
