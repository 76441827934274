import { AchieveTheme } from '@achieve/ascend'
import { createTheme } from '@mui/material'
import { merge } from 'lodash-es'

export const WEBSITES_LIST = ['website-tenant-1', 'website-tenant-2']

export const PAGE_LIST = {
  default: {
    websiteField: 'Achieve',
  },
  achieve: {
    websiteField: 'Achieve',
  },
  'website-tenant-1': {
    websiteField: 'Weekly Financial Solutions',
  },
  'website-tenant-2': {
    websiteField: 'FDR',
  },
}

const siteThemes = {
  'website-tenant-1': {
    palette: {
      primary: {
        main: '#9FBC00',
        light: '#839B00',
        dark: '#839B00',
      },
    },
    backgroundColors: {
      primary: '#F2F2F2',
      secondary: '#BAD256',
      accent: '#EBF1c9',
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            '[page-main-variation="website-tenant-1"] &.MuiButton-contained&.MuiButton-containedPrimary':
              {
                backgroundColor: '#9FBC00',
                '&:hover': {
                  backgroundColor: '#BAD526',
                },
              },
          },
        },
      },
    },
  },
  //TODO: Cleanup later
  'website-tenant-2': {
    palette: {
      primary: {
        main: '#3300ff',
        light: '#839B00',
        dark: '#839B00',
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            '[page-main-variation="website-tenant-2"] &.MuiButton-contained&.MuiButton-containedPrimary':
              {
                backgroundColor: '#3300ff',
                '&:hover': {
                  backgroundColor: '#3300ff',
                },
              },
          },
        },
      },
    },
  },
}

export const getPageContentTypes = () => {
  return PAGE_LIST[process.env.NEXT_PUBLIC_SITE_ENVIRONMENT] || PAGE_LIST.default
}

export const getSiteEnvironmentTheme = (siteEnvOverride) => {
  const env = siteEnvOverride || process.env.NEXT_PUBLIC_SITE_ENVIRONMENT
  if (!env || !siteThemes[env]) {
    return AchieveTheme
  }

  const siteEnvTheme = createTheme(merge(AchieveTheme, siteThemes[env]))
  return siteEnvTheme
}
