import { createContext, useReducer } from 'react'

const ArticlesFilterContext = createContext()

const ArticlesFilterReducer = (state, action) => {
  switch (action.type) {
    case 'INITIALIZE':
      return { ...state, ...action.payload }
    case 'SET_PAGE':
      return { ...state, page: action.payload }
    case 'SET_TITLE':
      return { ...state, title: action.payload }
    case 'SET_ARTICLES':
      return { ...state, articlesObject: action.payload }
    case 'SET_KEYWORDS':
      return { ...state, keywordsFilter: action.payload }
    case 'SET_FILTER_BY':
      return { ...state, filterBy: action.payload }
    case 'SET_ORDER_BY':
      return { ...state, orderBy: action.payload }
    default:
      return state
  }
}

const ArticlesFilterProvider = ({ children, defaultState = {} }) => {
  const [state, dispatch] = useReducer(ArticlesFilterReducer, defaultState)
  return (
    <ArticlesFilterContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </ArticlesFilterContext.Provider>
  )
}

export { ArticlesFilterProvider, ArticlesFilterContext }
