import { AchieveLink } from 'components/AchieveLink/AchieveLink'
import { Typography } from '@achieve/ascend'
import { SIGN_IN_ROUTE } from 'constants/navigation'
import { mainProductConfig } from 'services/contentful/helpers'
import { useContext } from 'react'
import { LayoutContext } from 'providers/LayoutProvider'

function SignInLink({ disabledRoutes = [], signInLinkUrl, signInLinkText, event, ...restProps }) {
  const { state: { siteEnv } = {} } = useContext(LayoutContext)
  if (disabledRoutes.includes(SIGN_IN_ROUTE)) {
    return null
  }

  return (
    <AchieveLink
      color="primary"
      href={signInLinkUrl ? signInLinkUrl : SIGN_IN_ROUTE}
      track={{
        ...{
          list_name: 'Header Nav Sign in Link',
          click_id: 'Sign in',
          click_text: `${mainProductConfig[siteEnv || 'achieve']?.['MainProductHyphen']} | Sign in`,
          nav_link_section: 'Header',
          track_event: 'global_header',
          event_action: 'link_click',
          event_type: 'link_click',
        },
        ...event,
      }}
      variant="bodySm"
      {...restProps}
    >
      <Typography fontWeight="medium">{signInLinkText ? signInLinkText : `Sign in`}</Typography>
    </AchieveLink>
  )
}

export { SignInLink }
