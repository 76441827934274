import { Button, Typography } from '@achieve/ascend'
import { AchieveLink } from 'components/AchieveLink/AchieveLink'
import { mainProductConfig } from 'services/contentful/helpers'
import { LayoutContext } from 'providers/LayoutProvider'
import { useContext } from 'react'

export default function HeaderCta({
  className = '',
  'data-testid': dataTestId,
  linkUrl,
  linkText,
  event,
  ...buttonProps
}) {
  const { state: { siteEnv } = {} } = useContext(LayoutContext)
  return (
    <AchieveLink
      className={className}
      href={linkUrl}
      data-testid={dataTestId}
      track={{
        ...{
          list_name: 'Header Nav Main CTA Button',
          click_id: 'Get Started',
          click_text: `${
            mainProductConfig[siteEnv || 'achieve']?.['MainProductHyphen']
          } | Get Started`,
          nav_link_section: 'Header',
          track_event: 'global_header',
          event_action: 'button_click',
          event_type: 'button_click',
        },
        ...event,
      }}
    >
      <Button color="primary" variant="contained" {...buttonProps}>
        <Typography variant="bodySm" fontWeight="bold">
          {linkText ? linkText : `Get Started`}
        </Typography>
      </Button>
    </AchieveLink>
  )
}
