import styles from './AuthorAvatar.module.scss'
import { MediaImageStandard, Typography } from 'components/Contentful'
import { AchieveLink } from 'components/AchieveLink'
import { mainProductConfig } from 'services/contentful/helpers'
import { useContext } from 'react'
import { LayoutContext } from 'providers/LayoutProvider'

function AuthorAvatar({ authorPhoto, index, href, authorName, priority = false }) {
  const { state: { siteEnv } = {} } = useContext(LayoutContext)
  return (
    <div className={styles['author-container']} data-testid="author-container">
      {authorPhoto ? (
        <MediaImageStandard
          className={styles['author-noimg']}
          alt={authorName}
          content={authorPhoto}
          layout="fixed"
          width={40}
          height={40}
          priority={priority}
          objectFit="cover"
          data-testid="author-image"
        />
      ) : (
        <div className={styles['author-noimg']} data-testid="author-no-image"></div>
      )}
      <div>
        <AchieveLink
          track={{
            list_name: 'AUTHOR VIEW PROFILE LINK',
            click_position: index,
            click_id: 'View Profile',
            click_text: `${mainProductConfig[siteEnv || 'achieve']?.['MainProductHyphen']} | View Profile`,
          }}
          href={href}
          data-testid="author-view-profile-link"
          className={styles['card-link']}
        >
          <Typography
            content={authorName}
            variant="labelSm"
            className={styles['author-link']}
            data-testid="author-name"
          />
        </AchieveLink>
        <Typography
          className={styles['author-title']}
          content={'Author'}
          variant="bodySm"
          data-testid="author-role-text"
        />
      </div>
    </div>
  )
}

export { AuthorAvatar }
export default AuthorAvatar
