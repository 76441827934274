import { Grid } from '@achieve/ascend'
import styles from './CardAppCTA.module.scss'
import { Eyebrow } from 'components/Eyebrow'
import { LinkButton, MediaImageStandard, Typography } from 'components/Contentful'
import AppStoreButtons from 'components/AppStoreButtons/AppStoreButtons'
import { isExternal } from 'utils/conversions'
import { useCurrentUrl } from 'hooks/useCurrentUrl'
import { mainProductConfig } from 'services/contentful/helpers'
import { useContext } from 'react'
import { LayoutContext } from 'providers/LayoutProvider'

export const CardAppCTA = ({ content }) => {
  let {
    eyebrow,
    cardTitleText,
    cardText,
    cardImage,
    cardLink,
    linkAndroid,
    linkApple,
    imageApple,
    imageAndroid,
  } = content
  const currentURL = useCurrentUrl()
  const { state: { siteEnv } = {} } = useContext(LayoutContext)


  return (
    <div>
      <Grid container className={styles['container']}>
        {cardImage && (
          <Grid item xs={12} lg={4} xl={4} className={styles['container-img']}>
            <MediaImageStandard
              className={styles['image']}
              alt={cardImage?.fields?.mediaAltText}
              content={cardImage?.fields?.file}
              data-testid="card-main-image"
              layout="fill"
              objectFit="fill"
              aspectRatio={3 / 2}
            />
          </Grid>
        )}
        <Grid
          item
          xs={12}
          lg={cardImage ? 8 : 12}
          xl={cardImage ? 8 : 12}
          className={styles['content']}
        >
          <Grid container spacing={1}>
            {eyebrow && (
              <Grid item xs={12}>
                <Eyebrow className={styles['title-eyebrow']} content={eyebrow} component="p" />
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography content={cardTitleText} variant="headingSm" />
            </Grid>
            <Grid item xs={12}>
              <Typography content={cardText} variant="bodySm" className={styles['content-text']} />
            </Grid>
            {(linkAndroid || linkApple) && (
              <Grid item xs={12}>
                <AppStoreButtons
                  appleImage={imageApple}
                  googleImage={imageAndroid}
                  appleButton={linkApple?.fields}
                  googleButton={linkAndroid?.fields}
                  navLinkSection="Value Props"
                />
              </Grid>
            )}
            {cardLink && (
              <Grid item xs={12}>
                <LinkButton
                  track={{
                    list_name: 'Cta Button',
                    click_id: cardLink?.fields?.linkText?.content[0]?.content[0]?.value,
                    click_text: `${mainProductConfig[siteEnv || 'achieve']?.['MainProductHyphen']} | ${cardLink?.fields?.linkText?.content[0]?.content[0]?.value}`,
                    nav_link_section: `Cta Button`,
                    click_type:
                      isExternal(cardLink?.fields?.linkHref, currentURL) ||
                      cardLink?.fields?.linkHref?.includes('get-started')
                        ? 'internal_campaign_click'
                        : 'page_nav_click',
                  }}
                  content={cardLink}
                  data-testid="card-cta-button"
                  typographicOptions={{ variant: 'labelMd' }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
